<template>
	<a-modal v-model="show" title="车辆使用申请" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="6" v-if="getOrganId() == 0">
					<a-form-model-item label="单位" prop="organId">
						<a-select v-model="formData.organId" placeholder="请选择单位" @select="selectorganId">
							<a-select-option value="">所有单位</a-select-option>
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
								organ.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6" v-if="getOrganId() == 0">
					<a-form-model-item label="部门" prop="depId">
						<select-depart @change="selectDepId" v-model="formData.depId" :value="formData.depId"
							:organId="formData.organId"></select-depart>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="请选择车辆" label-position="top" prop="carId">
						<select-car @input="e => (formData.carId = e[0])"></select-car>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="16">
					<a-form-model-item label="申请原因" label-position="top" prop="content">
						<a-input v-model="formData.content" placeholder="请输入申请原因"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="用车人" label-position="top">
						<select-staff @confirm="e => (formData.name = e[0].name)"></select-staff>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="开始时间" label-position="top" prop="startTime">
						<a-date-picker style="width:100%" :valueFormat="'YYYY-MM-DD HH:mm'" v-model="formData.startTime" show-time placeholder="请输入开始日期" />
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="结束时间" label-position="top" prop="stopTime">
						<a-date-picker style="width:100%" :valueFormat="'YYYY-MM-DD HH:mm'" v-model="formData.stopTime" show-time placeholder="请输入结束日期" />
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="目的地" label-position="top">
						<a-input v-model="formData.destination" placeholder="请输入目的地"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="里程" label-position="top">
						<a-input v-model="formData.mileage" type="number" placeholder="请输入行驶里程"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="备注信息">
						<a-input v-model="formData.remark" placeholder="请输入备注信息"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
		<a-spin size="large" fix v-if="loading" />
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import selectCar from './selectCar.vue';
import selectMaintain from './selectMaintain.vue';
import selectStaff from '@/components/common/select-staff.vue';
import selectDepart from '@/components/common/select-depart.vue';
export default {
	components: { selectCar, selectMaintain, selectStaff, selectDepart },
	data() {
		return {
			show: false,
			loading: false,
			organList: apiUtil.getOrganList(),
			CarList: [],
			AssignList: [],
			staffList: [],
			formData: {
				content: '',
				name: '',
				startTime: '',
				depId: '',
				organId: apiUtil.getOrganId(''),
				remark: '',
				destination: '',
				mileage: null,
				stopTime: '',
				carId: null,
				staffId: '',
				assignStaffId: null
			},
			formRule: {
				name: [{ required: true, message: '请输入用车人姓名' }],
				organId: [{ required: true, message: '请选择所属单位' }],
				depId: [{ required: true, message: '请选择所属部门' }],
				carId: [{ required: true, message: '请选择车牌号' }],
				content: [{ required: true, message: '请输入申请原因' }],
				name: [{ required: true, message: '请选择用车人' }],
				startTime: [{ required: true, message: '请选择开始时间' }],
				stopTime: [{ required: true, message: '请选择结束时间' }]
			}
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					this.formData = JSON.parse(JSON.stringify(form));
				} else {
					this.formData.content = '';
					this.formData.name = '';
					this.formData.startTime = '';
					this.formData.remark = '';
					this.formData.depId = '';
					this.formData.organId = apiUtil.getOrganId('');
					this.formData.destination = '';
					this.formData.mileage = '';
					this.formData.stopTime = '';
					this.formData.carId = null;
					this.formData.assignStaffId = null;
				}
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			console.log(this.formData);

			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					console.log(1111)
					request.post('/platform/car/record/save', this.formData).then(ret => {
						// console.log(ret)
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								// this.$refs.formRef.resetFields();
								this.show = false;
								this.$emit('success');
							});
						}
					})
				}
			});
		},

		// 获取车辆信息
		getCarList(id = this.formData.organId) {
			this.http
				.get('/platform/car/list', { organId: id })
				.then(ret => {
					console.log('获取汽车');
					this.CarList = ret.data.records;
				})
				.catch(err => { });
		},
		// 获取调度人员信息
		getAssignList(organId, id) {
			this.http
				.get('/platform/car/assign', { organId: organId, depId: id })
				.then(ret => {
					// console.log(ret.data);
					this.AssignList = ret.data;
				})
				.catch(err => { });
		},
		// 获取员工信息
		getstaffList(organId, id) {
			this.http
				.get('/platform/staff/list', { organId: organId, depId: id })
				.then(ret => {
					console.log(ret.data);
					this.staffList = ret.data.records;
				})
				.catch(err => { });
		},
		// 选择单位时
		selectorganId(e) {
			this.formData.depId = '';
			this.formData.assignStaffId = '';
			this.formData.name = '';
			this.formData.carId = '';
			this.getCarList(e);
			console.log(e);
		},
		// 选择部门时候触发
		selectDepId(e) {
			// console.log(e, '触发');
			this.formData.assignStaffId = '';
			this.formData.name = '';
			this.getAssignList(this.formData.organId, e);
			this.getstaffList(this.formData.organId, e);
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
</style>
